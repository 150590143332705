import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

// import Seo from '../../../components/seo'
// import Layout from '../../../components/layout'
// import Hero from '../../../components/hero'
import ArticlePreview from '../../../components/article-preview'
import Container from '../../../components/container'
import CategoryPreview from '../../../components/category-preview'
import TagPreview from '../../../components/tag-preview'

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.nodes')
    const categories = get(this, 'props.data.allContentfulCategory.nodes')
    const tags = get(this, 'props.data.allContentfulTag.nodes')

    return (
        <>
    {/* <Layout location={this.props.location}> */}
        {/* <Seo title="Blog" />
        <Hero title="Latest from the Blog" /> */}
        <ArticlePreview posts={posts} />
        <Container>
          <h1>Categories</h1>
          <CategoryPreview posts={categories} />
          <h1>Tags</h1>
          <TagPreview posts={tags} />
        </Container>
    {/* </Layout> */}
    </>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(
      limit: 3
      sort: { fields: [publishDate], order: DESC }
    ) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        image: heroImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          raw
        }
        metadata {
          tags {
            name
          }
        }
      }
    }
    allContentfulCategory {
      nodes {
        name
        slug
      }
    }
    allContentfulTag {
      nodes {
        name
      }
    }
  }
`
